


















































































import { Component, Vue, Watch } from "vue-property-decorator";
import store from "../../../store";
import { AxiosResponse } from "axios";
import Enum from "@/config/enum";
import { ReportApi } from "@/networks/ReportApi";
import BarChart from "@/views/components/charts/BarChart";
import Period from "@/views/components/inputs/period.vue";
import moment from "moment-jalaali";
import LineChart from "@/views/components/charts/LineChart";

@Component({
  components: {
    LineChart,
    BarChart: BarChart,
    period: Period
  }
})
export default class General extends Vue {
  private selectedPeriod = "-30 days";
  private options = Enum.ChartOptions;
  private mainEvent = {
    labels: [],
    datasets: []
  };

  private mainUser = {
    labels: [],
    datasets: []
  };

  private mainOffice = {
    labels: [],
    datasets: []
  };

  private mainOfficeCall = {
    labels: [],
    datasets: []
  };

  private mainCall = {
    labels: [],
    datasets: []
  };

  mounted() {
    this.all();
  }

  public all(): void {
    this.events();
    this.calls();
    this.users();
    this.officeCall();
    this.office();
  }

  public async events(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().mainEvent({
        period: this.selectedPeriod
      });
      this.mainEvent = {
        labels: res.data.data.dates.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: [
          {
            label: "تعداد رویدادهای ثبت شده روزانه",
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            data: res.data.data.vals
          }
        ] as any
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public officeChartColor(city: string): string {
    let color = "green";
    switch (city) {
      case "tabriz":
      case "تبریز ، ولیعصر":
        color = "red";
        break;
      case "mashhad":
      case "مشهد، فلسطین":
        color = "gray";
        break;
      case "tehran":
      case "تهران، جردن":
        color = "purple";
        break;
      case "shiraz":
      case "شیراز، قصردشت":
        color = "yellow";
        break;
      case "آنلاین":
        color = "blue";
        break;
      case "مسافرتی تیروژ":
        color = "orange";
        break;
      case "تیم سرچ":
        color = "green";
        break;
    }

    return color;
  }

  public async office(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().mainOffice({
        period: this.selectedPeriod
      });

      const data: any = [];
      Object.keys(res.data.data).forEach((x) => {
        data.push({
          label: x,
          backgroundColor: this.officeChartColor(x),
          data: res.data.data[x].val
        });
      });

      this.mainOffice = {
        labels: res.data.data["تبریز ، ولیعصر"].date.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: data
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async officeCall(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().mainOfficeCall({
        period: this.selectedPeriod
      });

      const data: any = [];
      Object.keys(res.data.data).forEach((x) => {
        data.push({
          label: x,
          backgroundColor: this.officeChartColor(x),
          data: res.data.data[x].val
        });
      });

      this.mainOfficeCall = {
        labels: res.data.data["tehran"].date.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: data
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async users(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().mainUsers({
        period: this.selectedPeriod
      });
      this.mainUser = {
        labels: res.data.data.dates.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: [
          {
            label: "تعداد کاربران ثبت شده روزانه",
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            data: res.data.data.vals
          }
        ] as any
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  public async calls(): Promise<void> {
    try {
      const res: AxiosResponse = await new ReportApi().mainCall({
        period: this.selectedPeriod
      });
      this.mainCall = {
        labels: res.data.data.dates.map((x: string) =>
          moment(x).format("jYYYY/jMM/jDD")
        ),
        datasets: [
          {
            label: "تعداد تماس ثبت شده روزانه",
            backgroundColor: this.$vuetify.theme.currentTheme.primary,
            data: res.data.data.vals
          }
        ] as any
      };
    } catch (error: any) {
      store.commit("showError", {
        message: error.response.data.message || "خطای نامشخص",
        color: "danger"
      });
    }
  }

  @Watch("selectedPeriod")
  public onSelectedPeriodChange(val: string, oldVal: string): void {
    if (val != oldVal) {
      this.all();
    }
  }
}
