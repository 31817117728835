import { Line, mixins } from "vue-chartjs";
import { Component, Vue, Prop } from "vue-property-decorator";

@Component({
  extends: Line,
  mixins: [mixins.reactiveProp]
})
export default class LineChart extends Vue {
  @Prop() readonly dataset!: Array<any>;
  @Prop() readonly options!: Record<string, any>;
  renderChart: any;
  mounted() {
    this.renderChart(this.dataset, this.options);
  }
}
